@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiAyp8kv8JHgFVrJJLmE3tF.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLmv1plEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLm21llEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLmr19lEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLm111lEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiGyp8kv8JHgFVrLPTedw.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLFj_V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLDz8V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiEyp8kv8JHgFVrFJA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLGT9V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLEj6V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLDD4V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLBT5V1s.ttf) format('truetype');
}
body {
  /* max-width: 1180px; */
  margin: auto;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
}
@media (max-width: 650px) {
  body {
    overflow-y: scroll;
  }
}
.back-arrow {
  margin-left: 75px;
}
.homeButton {
  height: 55px;
}
.homeButton svg {
  height: 100%;
  color: black;
}
.thank-you-card {
  max-width: 800px;
  margin: 50px auto;
  border: 1px solid transparent;
  border-radius: 15px;
  box-shadow: 0px 0px 9px 10px #e3e3e3;
  text-align: left;
  padding: 25px;
}
.thank-you-card .card {
  text-align: center;
}
.thank-you-card .card-title p {
  font-size: 28px;
  font-weight: 600;
}
.thank-you-card h5 {
  color: #0099db;
  font-size: 28px;
}
.thank-you-card .venmo-logo {
  width: 200px;
}
.thank-you-card .qr-code img {
  width: 200px;
}
.thank-you-card .qr-code-border img {
  width: 200px;
  border: solid 3px #0099db;
  border-radius: 10px;
  padding: 10px;
}
.thank-you-card .return-home {
  position: relative;
}
.thank-you-card .return-home button {
  background-color: #0099db;
  border: none;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  height: 45px;
  font-weight: bold;
  width: 220px;
  margin: 50px 0 25px 0;
}
.thank-you-card .return-home:after {
  content: '';
  background-image: url('/src/assets/images/v2/bear.svg');
  position: absolute;
  width: 245px;
  height: 210px;
  right: 0;
  bottom: -30px;
}
@media (max-width: 650px) {
  .thank-you-card .return-home:after {
    display: none;
  }
}
@media (max-width: 650px) {
  .ty-donation svg.fl-logo,
  .ty-info svg.fl-logo {
    display: none;
  }
}
.main-menu,
.donation,
.information,
.info-donation,
.ty-donation,
.payment-method,
.buttons {
  animation-name: fade-in;
  animation-duration: 1s;
  animation-iteration-count: 1;
}
.debit-card-payment {
  width: 60%;
  margin: auto;
}
.debit-card-payment .loading-animation img {
  position: absolute;
  width: 60px;
  right: 30%;
  animation: bounce 1s infinite;
}
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.debit-card-payment input {
  border-radius: 5px;
  border: none;
  background-color: #e3e3e3;
  height: 35px;
  border: 4px solid transparent;
}
.debit-card-payment input:focus {
  border: #0099db 4px solid;
}
.debit-card-payment .exp-cvc-wrapper {
  display: grid;
}
.debit-card-payment button {
  background-color: #0099db;
  border: none;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  height: 45px;
  font-weight: bold;
  width: 200px;
  margin: 50px 0 25px 0;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.top-bar {
  height: 150px;
}
.top-bar svg {
  width: 100px;
}
.main-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main-menu .title h1 {
  font-weight: bold;
  text-align: center;
}
.main-menu .buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin: 50px auto;
}
@media (max-width: 650px) {
  .main-menu .buttons {
    flex-direction: column;
  }
  .main-menu .buttons .dollar {
    margin-bottom: 30px;
  }
}
.main-menu .buttons a {
  text-decoration: none;
}
.main-menu .buttons .dollar,
.main-menu .buttons .bulb {
  border: 1px solid transparent;
  border-radius: 15px;
  box-shadow: 0px 0px 9px 10px #e3e3e3;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.main-menu .buttons .dollar svg,
.main-menu .buttons .bulb svg {
  width: 10vw;
  margin: 50px 100px;
}
.main-menu .buttons .dollar h4,
.main-menu .buttons .bulb h4 {
  color: #000;
  font-weight: bold;
  font-size: 38px;
  margin: 0;
}
.main-menu .buttons .dollar h4.bottom,
.main-menu .buttons .bulb h4.bottom {
  margin: 0 0 50px 0;
}
.information {
  text-align: center;
}
.information h1 {
  font-size: 1.5rem;
}
.information .card {
  max-width: 800px;
  margin: 25px auto;
  border: 1px solid transparent;
  border-radius: 15px;
  box-shadow: 0px 0px 9px 10px #e3e3e3;
  text-align: left;
  padding: 25px;
}
.information .card .card-title,
.information .card .card-sub-title {
  color: #0099db;
  font-size: 28px;
}
.information .card .card-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
@media (max-width: 650px) {
  .information .card .card-fields {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
}
.information .card .card-fields input {
  border-radius: 5px;
  border: none;
  background-color: #e3e3e3;
  height: 35px;
}
.information .card span {
  color: #808080;
  font-weight: 500;
}
.information .card .card-icons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
@media (max-width: 650px) {
  .information .card .card-icons {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
}
.information .card .card-icons .icon-item {
  justify-content: center;
  align-items: center;
  border: 3px solid #e3e3e3;
  border-radius: 15px;
  height: 120px;
  width: 220px;
  transition: ease 0.5s all;
  display: flex;
  flex-direction: column-reverse;
  color: #707070;
}
@media (max-width: 650px) {
  .information .card .card-icons .icon-item {
    width: 80%;
    margin: 10px auto;
  }
}
.information .card .card-icons .icon-item.selected {
  border: 3px solid #0099db;
  color: #0099db;
}
.information .card .card-icons .icon-item h6 {
  margin: 0;
  margin-top: 15px;
  font-size: 20px;
}
.information .card .sub-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.information .card button {
  background-color: #0099db;
  border: none;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  height: 45px;
  font-weight: bold;
  width: 150px;
  margin: 50px 0 25px 0;
}
.donation {
  text-align: center;
}
@media (max-width: 650px) {
  .donation h1 {
    font-size: 1.5rem;
    padding-right: 60px;
  }
}
.donation .card {
  max-width: 70%;
  margin: 50px;
  margin: 50px auto;
  border: 1px solid transparent;
  border-radius: 15px;
  box-shadow: 0px 0px 9px 10px #e3e3e3;
  text-align: left;
  padding: 10px 20px 10px 20px;
}
@media (max-width: 650px) {
  .donation .card {
    max-width: 80%;
  }
}
.donation .card .card-title,
.donation .card .card-sub-title {
  color: #0099db;
}
.donation .card .card-title h5,
.donation .card .card-sub-title h5 {
  font-size: 28px;
  margin: 25px 0;
}
.donation .card .card-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
@media (max-width: 650px) {
  .donation .card .card-fields {
    grid-template-columns: 1fr;
  }
}
.donation .card .card-fields input {
  border-radius: 5px;
  border: none;
  background-color: #e3e3e3;
  height: 35px;
}
.donation .card .donation-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.donation .card .donation-buttons .buttons {
  flex: 1 1 15%;
  margin: 10px 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.donation .card .donation-buttons .buttons button {
  align-items: center;
  justify-content: center;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  border-radius: 10px;
  background: white;
  border: 3px solid transparent;
  box-shadow: 0px 0px 4px 3px #e3e3e3;
  width: 100%;
  height: 150px;
  padding: 10px;
  transition: 0.5s all ease;
}
.donation .card .donation-buttons .buttons button h5 {
  margin: 0;
  margin: 5px 0px;
  color: #0099db;
  font-size: 20px;
  font-weight: bold;
}
.donation .card .donation-buttons .buttons button p {
  margin: 0;
  margin-bottom: 5px;
  font-size: 17px;
  text-align: center;
  flex-grow: 1;
  font-family: 'Poppins';
}
.donation .card .donation-buttons .buttons.selected button {
  background-color: #0099db;
}
.donation .card .donation-buttons .buttons.selected button h5,
.donation .card .donation-buttons .buttons.selected button p {
  color: white;
}
.donation .card .custom-amount-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.donation .card .custom-amount-input input {
  width: 75%;
  border-radius: 5px;
  border: solid 1px gray;
  margin-bottom: 18px;
}
.donation .card .custom-amount-input p {
  margin: 0;
}
@media (max-width: 1117px) {
  .donation .card .donation-buttons {
    flex-wrap: wrap;
  }
  .donation .card .donation-buttons .buttons {
    flex: 1 1 30%;
  }
}
@media (max-width: 576px) {
  .donation .card .donation-buttons .buttons {
    flex: 1 1 100%;
  }
}
.donation .card .custom-amount-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.donation .card .custom-amount-input input {
  width: 75%;
  border-radius: 5px;
  border: solid 1px gray;
  margin-bottom: 18px;
}
.donation .card .custom-amount-input p {
  margin: 0;
}
.donation .card .sub-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.donation .card .sub-button button {
  background-color: #0099db;
  border: none;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  height: 45px;
  font-weight: bold;
  width: 150px;
  margin: 50px 0 25px 0;
}
.donation .vertical-gallery {
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
}
@media (max-width: 650px) {
  .donation .vertical-gallery {
    display: none;
  }
}
.donation .vertical-gallery .gallery-item img {
  height: 20vh;
  width: 100%;
}
.payment-method {
  text-align: center;
}
.payment-method .buttons {
  max-width: 50%;
  margin: 50px auto;
  border: 1px solid transparent;
  border-radius: 15px;
  box-shadow: 0px 0px 9px 10px #e3e3e3;
  text-align: left;
  padding: 25px;
}
.payment-method .buttons h5 {
  color: #0099db;
  font-size: 28px;
}
.payment-method .buttons .payment-options {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin: 50px auto;
}
@media (max-width: 650px) {
  .payment-method .buttons .payment-options {
    flex-direction: column;
  }
  .payment-method .buttons .payment-options .debit-card-option {
    margin-bottom: 25px;
  }
}
.payment-method .buttons .payment-options .debit-card-option,
.payment-method .buttons .payment-options .venmo-option {
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 15px;
  box-shadow: 0px 0px 9px 10px #e3e3e3;
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 5px solid transparent;
  transition: border-color 0.3s ease;
}
.payment-method .buttons .payment-options .debit-card-option p,
.payment-method .buttons .payment-options .venmo-option p {
  color: black;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
.payment-method .buttons .payment-options .debit-card-option.selected,
.payment-method .buttons .payment-options .venmo-option.selected {
  border: 5px solid #0099db;
}
.payment-method .buttons .payment-options .debit-card-option.selected p,
.payment-method .buttons .payment-options .venmo-option.selected p {
  color: #0099db;
}
.payment-method .buttons button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #0099db;
  color: white;
  border: none;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.6px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}
.payment-method .buttons button:hover {
  background-color: #0056b3;
}
.debit-card-payment {
  text-align: center;
}
.debit-card-payment .input-field #card-number iframe,
.debit-card-payment .input-field #card-expiry iframe,
.debit-card-payment .input-field #card-cvv iframe,
.debit-card-payment .input-field #card-number #zipcode,
.debit-card-payment .input-field #card-expiry #zipcode,
.debit-card-payment .input-field #card-cvv #zipcode {
  height: 50px !important;
}
.debit-card-payment input {
  border-radius: 5px;
  border: none;
  background-color: #e3e3e3;
  height: 35px;
  border: 4px solid transparent;
}
.debit-card-payment input:focus {
  border: #0099db 4px solid;
}
.debit-card-payment .exp-cvc-wrapper {
  display: grid;
}
.debit-card-payment button {
  background-color: #0099db;
  border: none;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  height: 45px;
  font-weight: bold;
  width: 200px;
  margin: 50px 0 25px 0;
}
.thank-you-page-debit {
  text-align: center;
  padding: 20px;
}
.thank-you-page-debit .header {
  width: 200px;
}
.thank-you-page-debit h1 {
  color: #333;
  margin-top: 20px;
  text-align: center;
}
.thank-you-page-debit .thank-you-image {
  width: 100%;
  max-width: 800px;
  margin: 20px 0;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: -1;
}
.thank-you-page-debit .return-button {
  background-color: #dc3545;
  color: white;
  border: none;
  font-weight: 600;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  position: relative;
  bottom: -400px;
}
@media (max-width: 752px) {
  .thank-you-page-debit .return-button {
    bottom: -350px;
  }
}
@media (max-width: 665px) {
  .thank-you-page-debit .return-button {
    bottom: -300px;
  }
}
@media (max-width: 578px) {
  .thank-you-page-debit .return-button {
    bottom: -250px;
  }
}
.thank-you-page-debit .return-button:hover {
  background-color: #c82333;
}
.thank-you-page-venmo {
  text-align: center;
  padding: 20px;
}
.thank-you-page-venmo .header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
}
.thank-you-page-venmo .header .logo {
  width: 100px;
}
.thank-you-page-venmo h1 {
  color: #333;
  margin-top: 20px;
}
.thank-you-page-venmo .card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  max-width: 900px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-image: url('/src/assets/images/v2/thank_you_banner.jpg');
  background-size: contain;
}
.thank-you-page-venmo .card .image-container {
  flex: 1;
  max-width: 50%;
}
.thank-you-page-venmo .card .image-container .thank-you-image {
  width: 100%;
  height: auto;
  border-radius: 10px 0 0 10px;
}
.thank-you-page-venmo .card .qr-container {
  flex: 1;
  text-align: center;
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.thank-you-page-venmo .card .qr-container .venmo-logo {
  width: 150px;
  margin-bottom: 20px;
}
.thank-you-page-venmo .card .qr-container .qr-code {
  width: 200px;
  border: 5px solid #0099db;
  border-radius: 15px;
  margin-bottom: 20px;
}
.thank-you-page-venmo .card .qr-container .return-button {
  background-color: #0099db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
.thank-you-page-venmo .card .qr-container .return-button:hover {
  background-color: #007bb5;
}
